<template>
  <div>
    <!-- 搜索栏 -->
    <search-bar class="search-bar" v-model="keyword" @search="handleSearch"></search-bar>

    <!-- 列表 -->
    <div class="data-list">
      <!-- 列表项目 -->
      <div class="data-item" v-for="(item, index) in list" :key="index" @click="handleConfirmDownload(item.resourceID)">
        <!-- 内容块 -->
        <div class="body">
          <!-- 标题 -->
          <div class="title flex-row-between-center">
            <div class="one-line">{{item.name}}</div>
            <span class="iconfont icon-download"></span>
          </div>
          <!-- 时间、阅读 -->
          <div class="relevant-info flex-row-default-center">
            <!-- 时间 -->
            <div class="flex-center-center">
              <span class="iconfont icon-time"></span>
              <span class="one-line">{{item.createdAt.substring(0, 10)}}</span>
            </div>

            <!-- 阅读 -->
            <div class="flex-center-center">
              <span class="iconfont icon-download2"></span>
              <span class="download-count one-line">{{item.downloadCount}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 底标 -->
    <div class="no-more" v-if="isEnd">没有更多内容了</div>
  </div>
</template>

<script>
import page from '@/mixins/page'
import SearchBar from '@/components/SearchBar'
import { MessageBox, Toast } from 'mint-ui'

export default {
  name: 'ResourceList',

  data () {
    return {
      list: [],
      keyword: '',
      currentPage: 1,
      pageSize: 8,
      isEnd: false
    }
  },

  components: { SearchBar },

  mixins: [page],

  created () {
    this.pageSize = Math.ceil((this.getWindowHeight() - 30 - 32) / 103)
    this.getData()
  },

  beforeRouteLeave (to, from, next) {
    from.meta.keepAlive = false
    next()
  },

  methods: {
    getData () {
      this.api.getAllResource({ currentPage: this.currentPage, pageSize: this.pageSize, keyword: this.keyword }).then(res => {
        if (res.data.length === 0 || res.data.length < this.pageSize) this.isEnd = true
        this.list = [...this.list, ...res.data]
      }).catch(err => {
        if (err.status === 401) this.$store.commit('addLoginCallBack', () => { this.getData() })
      })
    },
    // 确认下载下载
    handleConfirmDownload (id) {
      MessageBox.confirm('确认发送到您的邮箱？').then(() => {
        this.handleDownload(id)
      }).catch(() => {})
    },
    // 下载
    handleDownload (id) {
      this.api.getResourceByIDViaEmail({ id }).then(res => {
        Toast(res.msg)
      }).catch(err => {
        if (err.status === 401) {
          this.$store.commit('addLoginCallBack', () => { this.handleDownload() })
        } else {
          if (err.data.code === 40024) {
            this.$router.push('/user/info')
          }
        }
      })
    },
    // 触底函数
    hitBottom () {
      if (this.isEnd) return
      this.currentPage++
      this.getData()
    },
    // 搜索
    handleSearch () {
      this.initPage()
      this.getData()
    }
  }
}
</script>

<style scoped>
.search-bar {
  margin: 15px;
}
.data-list {
  margin: 15px;
}
.data-item {
  border: 1px solid #dddddd;
  margin-top: 10px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 2px 2px 2px #eeeeee;
}

.body {
  padding: 5px 15px;
  font-size: 15px;
  color: var(--normal-font-color);
}

.title {
  font-weight: bold;
  padding: 10px;
  border-bottom: 1px solid #f5f5f5;
}
.icon-download {
  font-size: 20px;
  color: #aaa;
}
.relevant-info {
  padding: 10px;
  font-size: 13px;
  color: #999;
}
.relevant-info > div:first-child {
  margin-right: 10px;
}
.icon-time, .icon-download2 {
  margin-right: 3px;
}
.download-count {
  max-width: calc(100vw - 10vw - 60px - 100px);
}
</style>
